'use strict';

var initialized = false,
	util = require('./../../../app_storefront_core_UI/cartridge/js/util'),
	header = require('./../../../app_storefront_core_UI/cartridge/js/header'),
	options = {
		'classBody': 'js-header-flyout_opened js-utility_opened'
	},
	$cache = {};



/**
 * @private
 * @function
 * @description Initializes cache for component
 */
function initializeCache() {
	$cache.document = $(document);
	$cache.body = $('body');
	$cache.utilityFlyoutLinks = $('.js-utility_flyout-link').filter('[data-utility-popup!=".b-header-login_dropdown"]');
	$cache.utilityFlyoutPopups = $('.js-utility_flyout-item');
}


/**
 * @private
 * @function
 * @description Initializes events for component
 */
function initializeEvents() {
	if (!util.isMobile()) {
		$cache.utilityFlyoutLinks.filter('[data-utility-popup]').off('click');
		
		$cache.utilityFlyoutLinks
			.on('click', function(e) {
				e.preventDefault();
				var popupSelector = $(this).data('utility-popup');
				
				if (!$(this).hasClass('active')) {
					header.closeUtilityPopups();
					closeAllFlyouts(false);
					
					$(this).addClass('active');
					$cache.body.addClass(options.classBody);
					
					if (popupSelector) {
						$cache.utilityFlyoutPopups.filter(popupSelector).addClass('active');
						$cache.utilityFlyoutPopups.find('#q').focus();
					}
				} else {
					closeAllFlyouts(true);
				}
			});
		
		$cache.document
			.on('click', '.js-utility_flyout-close', function() {
				closeAllFlyouts(true);
			})
			.on('header.openpopup', function(event, customEvent) {
				if (!customEvent.container.hasClass('b-utility_popup-item')) {
					$(':ui-dialog').dialog('close');
					$cache.utilityFlyoutLinks.removeClass('active');
					$cache.body.removeClass(options.classBody);
				}
			})
			.on('dialogopen', function(e, ui) {
				var $dialogParent = $(e.target).parent(),
					isServiceDialog = $dialogParent.hasClass('dialog-customer_services'),
					isNewsletterDialog = $dialogParent.hasClass('dialog-newsletter_subscribe');
				
				if (isServiceDialog || isNewsletterDialog) {
					$dialogParent.css('margin-top', $('.js-sticky_header').height());
					$cache.body.addClass(options.classBody);
					
					if (isServiceDialog) $('.js-customer-service-dialog').addClass('active');
					if (isNewsletterDialog) $('.js-newsletter-subscribe-dialog').addClass('active');
				} else {
					closeAllFlyouts(true);
				}
			})
			.on('dialogclose', function(e) {
				!!$(e.target).is('#service-dialog') && closeAllFlyouts(true);
			});
	}
}


function closeAllFlyouts(hideOverlay) {
	$(':ui-dialog').filter('#service-dialog').dialog('close');
	$cache.utilityFlyoutPopups.removeClass('active');
	$cache.utilityFlyoutLinks.removeClass('active');
	!!hideOverlay && $cache.body.removeClass(options.classBody);
}


module.exports = {
	init : function (params) {
		if (initialized) return;
		initializeCache();
		initializeEvents();
		initialized = true;
	}
}

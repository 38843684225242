'use strict';

var $cache = {},
  options = {
    addToCart: '.js-add-to-cart',
    addToCartDisabled: '.js-add-to-cart-disabled',
    sizeErrorMessage: '.js-select_size-message',
    productActionButtons: '.js-product_actions',
    pdpMainSelector: '#pdpMain',
    addAllToCart: '.js-add-all-to-cart',
    swatches: '.js-swatches',
    swatchSelected: '.js-swatch-selected',
    variations: '.js-variations',
    errorClass: 'error',
    tooltip: '.js-options-tooltip',
    stickedClass: 'm-sticked',
    fullWidthClass: 'm-full_width',

    quantityFieldSelector: 'input[name="Quantity"]',
    personaliseProductClass: 'js-personalised_product',

    pdpVariationsAttr: '.js-pdp-attribute',
    pdpSizeVariationAttr: '.attribute-size'
  },

  dialog = require('./../../../../../app_storefront_core_UI/cartridge/js/dialog'),
  page = require('./../../../../../app_storefront_core_UI/cartridge/js/page.js'),
  util = require('./../../../../../app_storefront_core_UI/cartridge/js/util'),
  layout = require('./../../../../../app_storefront_core_UI/cartridge/js/layout');


function initializeConfig(params) {
  $.extend(options, params || {});
}

function initializeCache() {
  $cache.window = $(window);
  $cache.document = $(document);
  $cache.pdpMain = $(options.pdpMainSelector);
  $cache.addToCart = $(options.addToCart);
  $cache.addToCartDisabled = $(options.addToCartDisabled);
  $cache.addAllToCart = $(options.addAllToCart);
  $cache.productActionButtons = $(options.productActionButtons);
}

function initializeEvents() {
  $cache.document.on('click', options.addToCart, addToCartHandler);
  $cache.document.on('click', options.addToCartDisabled, addToCartDisabledHandler);
  $cache.addAllToCart.off('click');
  $cache.document.on('click', options.addAllToCart, addAllToCartHandler);
  $cache.document.on('product.variation.changed', function() {
    initializeCache();
  });

}

// Clear duplicate event listeners
// cancelling those added by _core_UI/cartridge/js/pages/product/addtocart.js
$(document).on('quickview.opened', function() {
  deInitializeEvents();
  initializeEvents();
});

function deInitializeEvents() {
  $cache.document.off('click', options.addToCart);
  $cache.document.off('click', options.addToCartDisabled);
  $cache.document.off('click', options.addAllToCart);
  $cache.document.off('product.variation.changed');
}

/**
 * @description Handler to handle the add to cart event
 */
function addToCartHandler(e) {
  e.preventDefault();
  var $actionButton = $(this);
  var $form = $actionButton.closest('form');

  if ($form.valid()) {
    $cache.document.trigger('before.additem.tocart');
  } else {
    $cache.document.trigger('form.addtocart.invalid');
    return;
  }

  var selectedProducts = $('.js-pquantity-input');
  var isSizeSelected = false;
  for (var i = 0; i < selectedProducts.length; i += 1) {
    var productDataField = $(selectedProducts[i]);
    if (productDataField.val() > 0) {
      isSizeSelected = true;
      break;
    }
  }
  if (!isSizeSelected) {
    $(options.sizeErrorMessage).show();
    $(this).removeClass('js-add-to-cart').addClass('js-add-to-cart-disabled').click();
    return;
  } else {
    $(options.sizeErrorMessage).hide();
  }

  addItemToCart($form).then(function(response) {
    var errorMsg = $(response).find('.js-minicart-error');
    if (errorMsg.length) {
      return;
    }
    var $uuid = $form.find('input[name="uuid"]');
    if ($uuid.length > 0 && $uuid.val().length > 0) {
      page.refresh();
    } else {
      // do not close quickview if adding individual item that is part of product set
      // @TODO should notify the user some other way that the add action has completed successfully
      if (!$(this).hasClass('sub-product-item') && 'closeAll' in dialog) {
        dialog.closeAll();
      }
      $cache.document.trigger('product.addedToCart', [response, $actionButton, $form]);
    }
  }.bind(this));
}

/**
 * @description Handler to handle the click on the disabled add to cart button
 */
function addToCartDisabledHandler(e) {
  e.preventDefault();
  var selectedProducts = $('.js-pquantity-input');
  var isSizeSelected = false;
  for (var i = 0; i < selectedProducts.length; i += 1) {
    var productDataField = $(selectedProducts[i]);
    if (productDataField.val() > 0) {
      isSizeSelected = true;
      break;
    }
  }
  if (!isSizeSelected) {
    $(options.sizeErrorMessage).show();
  } else {
    $(options.sizeErrorMessage).hide();
    $(this).removeClass('js-add-to-cart-disabled').addClass('js-add-to-cart').click();
    return;
  }

}

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
function addItemToCart() {
  var requestData = {products: []};
  var selectedProducts = $('.js-pquantity-input');
  var pdpProductSelectorsForm = $('#product-selectors-f');
  var addToCartErrorHolder = $('.js-addtocart-error-message');
  var errorMsg = null;
  for (var i = 0; i < selectedProducts.length; i += 1) {
    var productDataField = $(selectedProducts[i]);
    var pID = productDataField.data('itemId');
    var pQuantity = productDataField.val();
    if (pQuantity && pQuantity > 0) {
      requestData.products.push({pid: pID, quantity: pQuantity});
    }
  }

  if (!requestData.products.length || !pdpProductSelectorsForm.valid()) {
    pdpProductSelectorsForm.attr('disabled', 'disabled');
    return false;
  } else {
    pdpProductSelectorsForm.removeAttr('disabled');
  }
  requestData.isMany = true;
  requestData.products = JSON.stringify(requestData.products);
  return $.ajax({
    type: 'POST',
    url: util.ajaxUrl(Urls.addProduct),
    data: requestData,
    success: function(data) {
      if (data && addToCartErrorHolder.length) {
        errorMsg = $(data).find('.js-minicart-error');
        if (errorMsg.length) {
          addToCartErrorHolder.text(errorMsg.text()).removeClass('hidden');
        } else {
          addToCartErrorHolder.addClass('hidden');
        }
      }
      return data;
    }
  });
}

/**
 * @description Handler to handle the add all items to cart event
 */
function addAllToCartHandler(e) {
  var $productForms = [];
  var $allProductForms = $('#product-set-list').find('form').each(function() {
    $productForms.push(this);
  });
  e.preventDefault();

  if (!$productForms.length) {
    return;
  }

  addAllToCart($productForms, $productForms.pop());
}

function addAllToCart($allForms, $form) {
  addItemToCart($form).then(function(response) {
    if ($allForms.length == 0) {
      dialog.closeAll();
      // show the final response only, which would include all the other items
      $cache.document.trigger('product.addedToCart', response);
    } else {
      addAllToCart($allForms, $allForms.pop());
    }
  });
}

var addtocart = {
  init: function(params) {
    initializeConfig(params);
    initializeCache();
    initializeEvents();

    $(options.addToCart + '[disabled]').attr('title', $('.availability-msg').text());
  }
};
module.exports = addtocart;

'use strict';
var util = require('./util');

exports.init = function () {
	$(window).on('popstate', function() {
		if(history.state && !!history.state.length) {
            $('#main').html(history.state);
            util.scrollBrowser(0);
		} else {
			document.location.reload();
		}
	});
};
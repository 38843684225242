/** The references object of all modules needed for components initialization */
var references = {
	/** Page module references */
	'login' : require('./../../../app_storefront_core_UI/cartridge/js/login'),
	'cart' : require('./pages/cart'),
	'checkout' : require('./../../../app_storefront_core_UI/cartridge/js/pages/checkout'),
	'product' : require('./pages/product/index'),
	'search' : require('./../../../app_storefront_core_UI/cartridge/js/pages/search/index'),
	'storefront' : require('./pages/storefront'),
	'carousel' : require('./../../../app_storefront_core_UI/cartridge/js/carousel'),
	'page' : require('./../../../app_storefront_core_UI/cartridge/js/page'),
	'postmessagehandler': require('./../../../app_storefront_core_UI/cartridge/js/util/postmessagehandler'), 
	'customerservice': require('./../../../app_storefront_core_UI/cartridge/js/pages/service/customerservice'),
	'findorderform': require('./../../../app_storefront_core_UI/cartridge/js/pages/findorderform'),
	'searchorders' : require('./searchorders'),
	'browser-back-button' : require('./browser-back-button'),
	
	/** Core level components */
	'minicart' : require('./../../../app_storefront_core_UI/cartridge/js/minicart'),
	'tooltip' : require('./../../../app_storefront_core_UI/cartridge/js/tooltip'),
	'validator' : require('./validator'),
	
	
	/** Components from extension cartridge */
	'global.custom': require('./global.custom'),
	'header' : require('./../../../app_storefront_core_UI/cartridge/js/header'),
	'header.custom': require('./header.custom'),
	'searchsuggest' : require('./../../../app_storefront_core_UI/cartridge/js/searchsuggest'),
	'common-elements' : require('./../../../app_storefront_core_UI/cartridge/js/common-elements'),
	'input.numbers' : require('./inputnumbers'),
	'image': require('./../../../app_storefront_core_UI/cartridge/js/pages/product/image'),
	'product-actions' : require('./pages/product/product-actions'),
	'layout' : require('./../../../app_storefront_core_UI/cartridge/js/layout'),
	'dialog' : require('./../../../app_storefront_core_UI/cartridge/js/dialog/global'),
	'refinements': require('./../../../app_storefront_core_UI/cartridge/js/pages/search/refinements'),
	'stickykit' : require('./../../../app_storefront_core_UI/cartridge/js/stickykit'),
	'back-to-top' : require('./../../../app_storefront_core_UI/cartridge/js/back-to-top'),
	'product-tile' : require('./../../../app_storefront_core_UI/cartridge/js/product-tile'),
	'productsnippet' : require('./../../../app_storefront_core_UI/cartridge/js/productSnippet'),
	'timer' : require('./../../../app_storefront_core_UI/cartridge/js/timer')
};

/**
 * The components initialization configuration object
 *
 * @example New "Page" configuration
 *  var configuration = {
 *	  //...
 *	  'newpage' : {
 *		  'enabled' : true,
 *		  'options' : {},
 *		  'components' : {
 *			  'pagination' : {
 *				  'enabled' : false,
 *				  'options' : {}
 *			  }
 *		  }
 *	  }
 *	  //...
 *  }
 */
var configuration = {
	'global' : {
		'components': {
			'common-elements' : {},
			//'input.numbers': {},
			'layout' : {},
			'page' : {},
			'global.custom' : {},
			'header' : {},
			'header.custom' : {},
			'postmessagehandler' : {},
			'minicart' : {},
			'tooltip' : {},
			'validator' : {},
			'customerservice': {},
			'dialog' : {},
			'searchsuggest' : {
				'enabled' : true
			},
			'findorderform' : {
				'options' : {
					'findOrderForm' : '.find_order-form'
				}
			}
		}
	},
	'storefront': {
		'components' : {
			'timer' : {},
			'carousel' : {}
		}
	},
	'product' : {
		'options': {
			'showNavigation': false
		},
		'components' : {
			'product-actions' : {
				'options': {
					'allowedActions': {
						'addtocart': true,
						'requestinfo': true
					}
				}
			},
			'image': {
				'options': {
					'enableImgDots': true,
					'minimizedHeaderHeight': 91,
					'stickykit' : {
						'options' : {
							'spacer' : false,
							'parent' : '.js-pdp-product_images'
						}
					}
				}
			},
			'product-tile' : {},
			'productsnippet' : {}
		}
	},
	'search' : {
		'components' : {
			'refinements' : {},
			'back-to-top' : {},
			'timer' : {},
			'carousel' : {},
			'product-actions' : {
				'options': {
					'allowedActions': {
						'addtocart': true
					}
				}
			},
			'browser-back-button' : {}
		}
	},
	'cart' : {
		'components' : {
			'product-actions' : {
				'options': {
					'allowedActions': {
						'notifyme': true,
						'addtocart': true
					}
				}
			}
		}
	},
	'checkout' : {},
	'account' : {
		'components' : {
			'searchorders' : {}
		}
	},
	'login': {}
};

module.exports = {
	'configuration' : configuration,
	'references' : references
};
'use strict';

var options = {
		allowedActions: {
			'addtocart': true
		}
	},
	$cache = {};

var actions = {
	'notifyme': require('./../../../../../app_storefront_core_UI/cartridge/js/pages/product/notifyme'),
	'addtocart': require('./addToCart'),
	'backinstock': require('./../../../../../app_storefront_core_UI/cartridge/js/pages/product/backinstock'),
	'requestinfo': require('./../../../../../app_storefront_core_UI/cartridge/js/pages/product/requestinfo'),
	'storeavailability': require('./../../../../../app_storefront_core_UI/cartridge/js/pages/product/storeavailability')
};


function initializeConfig(params) {
	options = $.extend({}, options, params);
}


function initializeActions(params) {
	for (var action in options.allowedActions) {
		if (options.allowedActions[action]) {
			actions[action].init(params);
		}
	}
}


var productactions = {
	init: function(params) {
		initializeConfig(params);
		initializeActions(params);
	}
}

module.exports = productactions;

'use strict';

var account = require('./account'),
	quickview = require('./../../../../app_storefront_core_UI/cartridge/js/quickview');

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
	$('#cart-table').on('click', '.js-order_product-edit_link', function (e) {
		e.preventDefault();
		quickview.show({
			url: e.target.href,
			source: 'cart'
		});
	})
	.on('change', '.js-cart_quantity_field', function (e) {
		setTimeout(function() {
			$('.js-cart-update').trigger("click");
		}, 2000);
	});
	// override enter key for coupon code entry
	$('form input[name$="_couponCode"]').on('keydown', function (e) {
		if (e.which === 13 && $(this).val().length === 0) { return false; }
	});

	//to prevent multiple submissions of the form when removing a product from the cart
	var removeItemEvent = false;
	$('button[name$="deleteProduct"]').on('click', function (e) {
		if (removeItemEvent) {
			e.preventDefault();
		} else {
			removeItemEvent = true;
		}
	});
}

exports.init = function () {
	initializeEvents();
	account.initCartLogin();
};

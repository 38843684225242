'use strict';

var util = require('./../../../app_storefront_core_UI/cartridge/js/util');

var $cache = {},
	options = {
		searchOrderNoInput: '#OrderSearchForm_OrderNo',
		searchOrderSKUInput: '#OrderSearchForm_Sku',
		searchOrdersButton: '#OrderSearchForm_Submit',
		searchOrdersPagination: '.js-search-orders-pagination a'
	};

function initializeCache() {
	$cache.body = $('body');
	$cache.searchOrderNoInput = $(options.searchOrderNoInput);
	$cache.searchOrderSKUInput = $(options.searchOrderSKUInput);
	$cache.searchOrdersButton = $(options.searchOrdersButton);
}

function initializeEvents() {
	$cache.searchOrderNoInput.keyup(function (e) {
		if (e.keyCode === 13) {
			searchOrders();
		}
	});
		
	$cache.searchOrderSKUInput.keyup(function (e) {
		if (e.keyCode === 13) {
			searchOrders();
		}
	});
	
	$cache.searchOrdersButton.click(function(){
		searchOrders();
	});
	
	$cache.searchOrdersPagination = $(options.searchOrdersPagination);
	
	$cache.body.on('click', options.searchOrdersPagination, function(e) {
		e.preventDefault();
		
		searchOrders( $(this).attr('href') );
	});
}

function searchOrders(link) {
	var searchOrdersUrl;
	
	if( link ) {
		searchOrdersUrl = link;
	}
	else {
		searchOrdersUrl = util.appendParamsToUrl( Urls.searchOrdersUrl, {
			orderNo: $cache.searchOrderNoInput.val(),
			productNo: $cache.searchOrderSKUInput.val()
		});
	}
	
	$.ajax({
		type: 'GET',
		dataType: 'html',
		url: searchOrdersUrl,
		success: function (response) {
			$("#js-orders-list").html( response );
		}
	});
}

module.exports = {
	init: function() {
		initializeCache();
		initializeEvents();
	}
};



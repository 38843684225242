'use strict';

function disableInputFocus(){
	$('body').on('focus', 'input[type="number"]',function(e){
		e.stopPropagation();
		e.preventDefault();
		$(this).trigger('blur');
	});
}
var inputNumbersHandler = {
	init: function(){
		disableInputFocus();
	}
}
 
module.exports = inputNumbersHandler;

